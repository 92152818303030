<template>
  <div class="container-fluid py-4 all-students">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
            <div class="row">
              <div class="col-lg-6">
              <h6 class="text-white text-capitalize ps-3"> {{$t('message.receipts')}} |
               {{$t('message.fromDate')}} : <span class="">{{fromDate}}</span>
                {{$t('message.toDate')}}  : <span class="">{{toDate}}</span>
              </h6>
              </div>
               <div class="col-lg-6">
                  <!-- <router-link to="/receipts/create-revesion">
               <span class="badge badge-sm  new-student"><i class="fas fa-location-arrow"></i>إيصال مراجعة  </span>
                 </router-link> -->
                <!-- <router-link to="/receipts/create-offer">
                  <span class="badge badge-sm  new-student"><i class="fas fa-location-arrow"></i>إيصال عرض  </span>
                </router-link> -->
                     <router-link to="/receipts/create">

               <span class="badge badge-sm  new-student"><i class="fas fa-location-arrow"></i> {{$t('message.createReceipt')}} </span>
                 </router-link>
               </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <!-- filters -->
            <div class="row align-center padding">
              <div class="mb-3 col-lg-3">
                <label>  {{$t('message.fromDate')}}</label>
                <vmd-input type="date" v-model="fromDate" />
              </div>
              <div class="mb-3 col-lg-3">
                <label>  {{$t('message.toDate')}}</label>
                <vmd-input type="date" v-model="toDate" />
              </div>
              <div class="mb-3 col-lg-3">
                <label> {{$t('message.users')}}</label>
                <multiselect
                  class='required form-control'
                  id='grade'
                  v-model="selectedUser"
                  :options='users'
                  :searchable='true'
                  :close-on-select='true'
                  :placeholder="$t('message.select')"
                  label='name'
                  track-by='id'
                  required='required'
                  :internal-search="true"
                >
                  <template v-slot:noOptions>
                    <span> فارغة </span>
                  </template>
                  <template v-slot:noResult>
                    <span>لا يوجد نتيجة </span>
                  </template>
                </multiselect>
              </div>
              <div class="text-center col-lg-1  ">
                <vmd-button
                  class=""
                  variant="gradient"
                  color="info"
                  @click="filterData()"
                  >  {{$t('message.filter')}} </vmd-button>
              </div>
              <div class="text-center col-lg-1 ">
                <vmd-button
                  class=""
                  variant="gradient"
                  color="danger"
                  @click="clearData()"
                >  {{$t('message.delete')}}</vmd-button>
              </div>
            </div>
            <div class="row">
              <vdtnet-table class=" table-striped table table-borderless text-center"
                hide-select
                single-select
                :jquery="jq"
                ref="table"
                :fields="fields"
                :opts="options"
                @edit="doAlertEdit"
                @show="doAlertShow"
                @showStudent="doStudentShow"
                @delete="doAlertDelete"
                @reloaded="doAfterReload"
                @table-creating="doCreating"
                @table-created="doCreated"
              >
              </vdtnet-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VmdInput from '@/components/VmdInput.vue'
import VdtnetTable from 'vue-datatables-net'
import VmdButton from '@/components/VmdButton.vue'
import $ from 'jquery'
let selectedUser = {}
const date = new Date()
const today =
  date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
let fromDate = today
let toDate = today
export default {
  name: 'Receipts',
  components: {
    VmdInput,
    VdtnetTable,
    VmdButton
  },
  data () {
    return {
      users: [],
      selectedUser: selectedUser,
      fromDate: today,
      toDate: today,
      pagination: {
        rowsPerPage: 30
      },
      jq: $,
      options: {
        ajax: {
          headers: {
            'Accept-Language': 'ar'
          },
          beforeSend: function (xhr) {
            xhr.setRequestHeader(
              'Authorization',
              'Bearer ' + localStorage.token
            )
          },
          url: `${this.$hostUrl}receipts`,
          data: function (d) {
            d.user_id = selectedUser
            d.from_date = fromDate
            d.to_date = toDate
          }
        },
        buttons: [
          {
            extend: 'print',
            text: '',
            titleAttr: 'طباعة',
            className: 'fas fa-print btn-primary',
            exportOptions: {
              columns: 'th:not(:last-child)'
            },
            customize: function (win) {
              $(win.document.body).css('direction', 'rtl')
            }
          },
          {
            extend: 'copy',
            text: '',
            className: 'fas fa-copy btn-warning',
            titleAttr: 'نسخ',
            exportOptions: {
              columns: 'th:not(:last-child)'
            }
          },
          {
            extend: 'excel',
            text: '',
            titleAttr: 'إكسل',
            className: 'fas fa-table btn-success',
            exportOptions: {
              columns: 'th:not(:last-child)'
            }
          }
        ],
        dom:
          "<'row'<'col-sm-12 col-md-6'f><'col-sm-12 col-md-6 added'l>>" +
          "<'row'<'col-sm-12'Btr>>" +
          "<'row'<'col-sm-12 col-md-6'p><'col-sm-12 col-md-6'i>>",
        paging: true,
        language: {
          url: this.$t('message.fromDate'),
          processing: '<i class="fa fa-spinner fa-spin" style="font-size:40px;color:#7aeb7f"></i>'
        },
        responsive: true,
        processing: true,
        searching: true,
        searchDelay: 1500,
        destroy: true,
        ordering: true,
        order: [[9, 'desc']],
        lengthChange: true,
        serverSide: true,
        fixedHeader: true,
        saveState: true,
        select: false
      },
      fields: {
        code: {
          label: this.$t('message.code'),
          sortable: true,
          searchable: true
        },
        description: {
          label: this.$t('message.description'),
          sortable: true,
          searchable: true
        },
        'student.number': {
          label: this.$t('message.id'),
          sortable: true,
          searchable: true,
          render: this.addStudentLink
        },
        'student.full_name': {
          label: this.$t('message.name'),
          sortable: true,
          searchable: true
        },
        'student.mobile': {
          label: this.$t('message.mobile'),
          sortable: true,
          searchable: true
        },
        transaction_type_name: {
          label: this.$t('message.receiptType'),
          sortable: true,
          searchable: true
        },
        'user.user_name': {
          label: this.$t('message.user'),
          sortable: true,
          searchable: true
        },
        amount: {
          label: this.$t('message.value'),
          sortable: true,
          searchable: true
        },
        created_at: {
          label: this.$t('message.createdAt'),
          sortable: true,
          searchable: true
        },
        actions: {
          isLocal: true,
          label: this.$t('message.details'),
          defaultContent:
            '&ensp;<a href="javascript:void(0);" data-action="show"><i class="mdi mdi-square-edit-outline"></i> <span class="badge badge-sm bg-gradient-info">' + this.$t('message.details') + '</span></a>'
        }
      }
    }
  },
  created () {
    const users = []
    this.$http.get(`${this.$hostUrl}all-users`).then((response) => {
      $.each(response.data.data, function (key, val) {
        users.push({
          name: val.name,
          id: val.id
        })
      })
      this.users = users
    })
  },
  methods: {
    addStudentLink  (data, type, row, meta) {
      return `<a href="javascript:void(0);" class="page-link" data-action="showStudent">${data}</a>`
    },
    filterData () {
      selectedUser = this.selectedUser.id
      fromDate = this.fromDate
      toDate = this.toDate
      const table = this.$refs.table
      table.reload()
    },
    clearData () {
      selectedUser = {}
      this.selectedUser = {}
      this.fromDate = today
      this.toDate = today
      fromDate = today
      toDate = today
      this.$refs.table.reload()
    },
    doAlertShow (data) {
      this.$router.push({
        path: `/receipts/${data.id}/show`,
        params: { id: data.id }
      })
    },
    doStudentShow (data) {
      console.log('data show', data)
      this.$router.push({
        path: `/students/${data.student.id}/show`,
        params: { id: data.id }
      })
    },
    doAlertEdit (data) {
      this.$router.push({
        path: `/receipts/${data.id}/edit`,
        params: { id: data.id }
      })
    }
  }
}
</script>
<style>
.ps-3 {
    padding-right: 1rem !important;}
    .me-3 {
    margin-left: 1rem !important;}
</style>
